import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device, width } from '../styles/device';



export const ProductSlugStyled = styled.div`
    margin-top: ${palette.headerTabletHeight};

    @media ${device.tablet} {
        margin-top: ${palette.headerHeight};
    }

    .content-wrapper {
        max-width: 100%;
        margin: 0px auto 0 auto;

        .section-wrapper  {
            width: 100%;

            .section-inner {
                margin: 0 auto;
                max-width: ${width.xl};

                .section {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 50px;
                    padding-bottom: 100px;

                    @media ${device.tablet} {
                        flex-direction: row;  
                    }

                    .product-image {
                        margin-right: 0;
                        width: 100%;

                        @media ${device.tablet} {
                            margin-right: 30px;
                        }

                        .imageContainer {
                            max-width: 500px;
                            margin: 0 auto;
                            border: 1px solid ${palette.borderGrey};

                        }
                        
                    }

                    .product-detail {
                        margin-top: 2rem;
                        width: 100%;
                

                        @media ${device.tablet} {
                            margin-top: 0;
                            max-width: 500px;
                        }
                        .title {
                            margin-bottom: 1rem;
                            color: ${palette.cta};
                        }

                        .textWrap {
                            margin-bottom: 2rem;

                            @media ${device.tablet} {
                                margin-bottom: 4rem;
                            }
                        }
                        .sizeNameWrap {
                            color: ${palette.lightText};
                            font-size: ${palette.fontSizeSm};
                            margin-bottom: 1rem;

                            .sizeName {
                                color: ${palette.black};
                                font-weight: 600;
                            }
                        }

                        .buttonWrapper {
                            margin-top: 3rem;

                            .contact {
                                a {
                                    padding: .6rem 1rem;
                                    font-size: ${palette.fontSizeSsm};
                                    max-width: 100%;
                                }
                            }
                        }

                        .detailWrapper {
                            margin-top: 2rem;

                            .title {
                                font-weight: 700;
                                border-bottom: 1px solid ${palette.borderGrey};
                                padding-bottom: 1rem;
                            }

                            .textWrap {
                                p {
                                    line-height: 1.8rem;
                                }
                            }
                        }
                    }

                }
            }
        } 
    }

    .colorGroupWrapper {
        display: flex;
        margin-bottom: 1rem;

        .colorBox {
            width: 35px;
            height: 35px;
            margin-right: 20px;
            border: 1px solid ${palette.mediumGrey};

            @media ${device.tablet} {
               width: 25px;
                height: 25px;
                margin-right: 20px; 
            }

            &.blue {
                background-color: ${palette.colorPickBlue};
            }
            &.white {
                background-color: ${palette.colorPickWhite};
            }
            &.black {
                background-color: ${palette.colorPickBlack};
            }
            &.purple {
                background-color: ${palette.colorPickPurple};
            }
        }
    }

    .colorNameWrap {
        color: ${palette.lightText};
        font-size: ${palette.fontSizeSm};
        margin-bottom: 1rem;

        .colorName {
            color: ${palette.black};
            font-weight: 600;
        }
    }
 
`;
