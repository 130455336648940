import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import {ProductSlugStyled} from './productSlugStyled'
import emptyImage from '../images/image-symbol.jpg'
import Markdown from 'react-markdown'
import ButtonCta from '../components/buttonCta'
import BreadCrumb from '../components/breadCrumb'



class ProductSlug extends Component {
  constructor(props) {
    super();
    this.state = {
      onColor: '',
      colorSlug: '',
      imageSet: []
    };
  }
  componentDidMount() {
    const { colorWithImage } = this.props.data.contentfulProducts
    if(colorWithImage && colorWithImage.length > 0) {
      this.setState({ onColor: colorWithImage[0].colorName})
    }

    if(this.state.colorSlug === '' && colorWithImage && colorWithImage.length > 0) {
      this.setState({ colorSlug: colorWithImage[0].colorSlug})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.colorSlug !== this.state.colorSlug) {

       const { colorWithImage } = this.props.data.contentfulProducts
        return colorWithImage && colorWithImage.map((c) => {
          if(c.colorSlug === this.state.colorSlug) {
            this.setState({ imageSet: c.imageSet })
          }
      })
    }
  }

  mouseEnter = (colorName, colorSlug) => {
    this.setState({ onColor: colorName, colorSlug: colorSlug })
  }

  onClick = (colorName, colorSlug) => {
    this.setState({ onColor: colorName, colorSlug: colorSlug })
  }

  renderSize = () => {
    const { sizes } = this.props.data.contentfulProducts
    const size = sizes.join(', ')
    return <span className="size">{size}</span>
  }

    renderColor = () => {
      const { colorWithImage } = this.props.data.contentfulProducts
      return colorWithImage && colorWithImage.map((c) => {

         switch(c.colorSlug) {
          case 'blue':
              return <div key={c.id} className={`colorBox blue`} onMouseEnter={this.mouseEnter.bind('', c.colorName, c.colorSlug)} onClick={this.onClick.bind('', c.colorName, c.colorSlug)}></div>
          case 'white':
              return <div key={c.id} className={`colorBox white`} onMouseEnter={this.mouseEnter.bind('', c.colorName, c.colorSlug)} onClick={this.onClick.bind('', c.colorName, c.colorSlug)}></div>
          case 'black':
              return <div key={c.id} className={`colorBox black`} onMouseEnter={this.mouseEnter.bind('', c.colorName, c.colorSlug)} onClick={this.onClick.bind('', c.colorName, c.colorSlug)}></div>
          case 'purple':
              return <div key={c.id} className={`colorBox purple`} onMouseEnter={this.mouseEnter.bind('', c.colorName, c.colorSlug)} onClick={this.onClick.bind('', c.colorName, c.colorSlug)}></div>
          default:
              return '';
          }
      })
  }

  renderImage = () => {
    if(this.state.imageSet.length > 0) {
      return this.state.imageSet.map((img) => {
        return <Img key={img.id} className="productImage" alt={img.file.fileName} sizes={img.sizes} placeholderStyle={{ visibility: "hidden" }} />


      })
    } else {
      return <img src={emptyImage} alt={`image`} className="productImage"/>
    }
  }

  renderDetail = () => {
    const { detail } = this.props.data.contentfulProducts
    return detail && detail.map((d) => {
      return <div key={d.id}>
        {d.title && <div className="title">{d.title}</div>}
        {d.text && <div className="textWrap"><Markdown source={d.text.text} /></div>}
      </div>
    })
  }

    render() {
      const { title, slug, text, detail, category } = this.props.data.contentfulProducts
      const descriptionMeta = title ? title : 'Savie Glove'

        return (
          <Layout hideContact={true} backgroundColor={false}>
            <SEO title={slug} description={descriptionMeta} />
              <ProductSlugStyled>
                <BreadCrumb className="productSlug" slug={category.name} breadCrumbTitle={title} categoryTitle={category.title} />
                <div className="content-wrapper">
                  <div className="section-wrapper">
                      <div className="section-inner">
                        <div className="section">
                          <div className="product-image">
                            <div className="imageContainer">
                              {this.renderImage()}
                            </div>
                          </div>
                          <div className="product-detail">
                            {title && <h1 className="title">{title}</h1>}
                            {text && <div className="textWrap"><Markdown source={text.text} />
                          </div>}
                          <p className="sizeNameWrap">Size:<span className="sizeName"> {this.renderSize()}</span></p>
                          <p className="colorNameWrap">Color:<span className="colorName"> {this.state.onColor}</span></p>
                          <div className="colorGroupWrapper">
                            {this.renderColor()}
                          </div>

                          <div className="buttonWrapper">
                            <ButtonCta text="Contact us" className="contact" linkTo={`/contact/`} isCta={true} />
                          </div>
                          <div className="detailWrapper">
                              {this.renderDetail()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ProductSlugStyled>
            </Layout>
        )
    }
}

ProductSlug.propTypes = {
    data: PropTypes.object.isRequired
}

export default ProductSlug;

export const pageQuery = graphql`
  query ProductBySlug($slug: String!) {
    contentfulProducts(slug: { eq: $slug }) {
      title
      slug
        id
      text {
        text
      }
      color
      sizes
      colorWithImage {
        colorSlug
        colorName
        id
        imageSet {
          id
          file {
            fileName
            url
          }
          sizes(maxWidth: 900, quality: 90, resizingBehavior: PAD) {
            ...GatsbyContentfulSizes_withWebp
            sizes
            src
          }
        }
      }
      detail {
        id
        title
        text {
          text
        }
      }
      category {
        name
        title
      }
    }
  }
`