import React from "react"


const ArrowRight = ({name}) => {
    return (
        <svg className={name} height="1em" width="1em" focusable="false" fill="currentColor" viewBox="0 0 24 24">
            <path d="M7.63 21.14a.75.75 0 010-1.06l7.55-7.55c.3-.3.3-.77 0-1.06L7.63 3.92a.75.75 0 111.06-1.06l7.55 7.55c.88.88.88 2.3 0 3.18L8.7 21.14a.75.75 0 01-1.06 0z"></path>
        </svg>

    )
}

export default ArrowRight
