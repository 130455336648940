import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device, width } from '../styles/device'



export const BreadCrumbStyled = styled.div`
    max-width: ${width.xxl};
    margin: 0 auto;
    margin-top: 2rem;
    padding-left: 15px;
    padding-right: 15px;

    @media ${device.desktop} {
        padding-left: 50px;
        padding-right: 50px;
    }

    .breadCrumb {
        font-size: .7rem;
        display: flex;
        align-items: center;
        color: #aaa;
        line-height: 20px;
        color: ${palette.lightText};

            .step1, .step2 {
                cursor: pointer;
                text-decoration: none;
                display: flex;
                align-items: center;
                color: ${palette.lightText};
                line-height: 30px;

            &:hover {
                color: ${palette.cta};
                font-weight: 700;
            }

        }

        .wrapper {
            display: flex;
            flex-wrap: wrap;
        }

        .step2.active {
                color: ${palette.cta};
                font-weight: 700;
                cursor: unset;
                display: flex;
                align-items: center;
            }

        .slash {
            margin-left: 5px;
            margin-right: 5px;
        }

        svg.breadCrumb {
            margin-left: 8px;
            margin-right: 8px;
            width: 12px;
            height: 12px;

            &.home {
                margin-right: 0;
            }

            path {
                fill: ${palette.lightText};
                &:hover {
                    fill: ${palette.cta};
                }

            }
        }
    }

    &.productSlug {
        margin-top: 6rem;

        @media ${device.tablet} {
            margin-top: 8rem;
        }
    }

`;
