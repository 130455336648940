import React from 'react'
import { Link } from "gatsby"
import {BreadCrumbStyled} from './breadCrumbStyled'
import HomeIcon from './icons/homeIcon'
import PropTypes from 'prop-types'
import ArrowRight from './icons/arrowRight'




const BreadCrumb = ({className, slug, categoryTitle, breadCrumbTitle}) => {
    const pathName = () => {
        const items = [];
        let path
        if (typeof window !== 'undefined') {
            path = window.location.pathname ? window.location.pathname : ''
        }

        const pathSplit = path && path.split("/")
        const lastPath = pathSplit && pathSplit[pathSplit.length - 2];

        if(!categoryTitle && breadCrumbTitle) {
            items.push(<span key={breadCrumbTitle} className="wrapper">
                <Link to={`/`} className="step1"><HomeIcon name="breadCrumb home"/></Link>
                <span className={`step2 active`}><ArrowRight name="breadCrumb"/>{breadCrumbTitle}</span>
            </span>)
        } else if (categoryTitle && breadCrumbTitle) {
            items.push(<span key={breadCrumbTitle} className="wrapper">
                <Link to={`/`} className="step1"><HomeIcon name="breadCrumb home"/></Link>
                <Link to={`/${slug}/`} className={`step2`}><ArrowRight name="breadCrumb"/>{categoryTitle}&nbsp;</Link>
                <span className={`step2 active`}><ArrowRight name="breadCrumb"/>{breadCrumbTitle}</span>
            </span>)
        }

        return items
    }

    return <BreadCrumbStyled className={`${className}`}>
            <div className={`breadCrumb`}>
                {pathName()}
            </div>
        </BreadCrumbStyled>
}

BreadCrumb.propTypes = {
    className: PropTypes.string.isRequired,
    slug: PropTypes.string,
    breadCrumbTitle: PropTypes.string.isRequired,
    categoryTitle: PropTypes.string
}


export default BreadCrumb

